/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Image, Subtitle, Text, Button } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Kavárna MANETKA - Kontakt"} description={"Kontaktní a informační strana kavárny"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="js-anim  --anim3 pt--60" anim={"3"} name={"kontakty"} style={{"paddingBottom":75}} layout={"l2"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" style={{"paddingTop":4,"marginBottom":48}} content={"<span style=\"color: var(--black);\">Kontakty a mapa</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" style={{"maxWidth":1478,"marginTop":0,"marginBottom":0,"paddingBottom":0}} columns={"3"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/i/moduls/email-flat-1.svg"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":60}} svgColor={"rgba(0,0,0,1)"} RootClassName={"column__icon"}>
              </Image>

              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--black);\"><span style=\"text-decoration-line: underline;\"><a href=\"mailto:kavarna@manetka.cz?subject=Email+z+kontaktu+na+webu\" target=\"_blank\">kavarna@manetka.cz</a></span><br><br></span>"}>
              </Subtitle>

              <Text className="text-box" content={"Prosíme, veškeré rezervace je potřeba si potvrdit<br>s personálem kavárny. Proto, pokud spěcháte, doporučujeme raději zavolat."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/i/moduls/phone-flat-1.svg"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":60}} svgColor={"rgba(0,0,0,1)"} RootClassName={"column__icon"}>
              </Image>

              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--black);\"><span style=\"text-decoration-line: underline;\"><a href=\"tel:+420773300833\">+420 773 300 833</a></span><br><br></span>"}>
              </Subtitle>

              <Text className="text-box" content={"Tímto telefonem se dovoláte přímo do naší kavárny, proto prosíme volejte v otevírací době, kterou uvádíme níže."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/i/moduls/adress-flat-1.svg"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":60}} svgColor={"rgba(0,0,0,1)"} RootClassName={"column__icon"}>
              </Image>

              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--black); text-decoration-line: underline;\"><a href=\"https://maps.app.goo.gl/jy8MpX4o57Zem2B4A\" target=\"_blank\">Manětínská 2337/30,<br>Plzeň</a></span><br>"}>
              </Subtitle>

              <Text className="text-box" content={"<span style=\"color: var(--black);\">Pro zobrazení mapy Google klikněte na adresu výše, pro zobrazení virtuální prohlídky kavárny klikněte zde:&nbsp;</span><span style=\"font-weight: bold; color: var(--black);\"><a href=\"https://www.google.com/maps/place/Manetka+cafe/@49.7800782,13.3664327,3a,90y,117.55h,89.06t/data=!3m7!1e1!3m5!1sAF1QipPbAylCirgJw9XZmzsz7O3ipPU8vZNmJViK7vnr!2e10!3e12!7i11000!8i5500!4m9!3m8!1s0x470af33bb34100a5:0x17c49104135d9431!8m2!3d49.7798029!4d13.3665938!10e5!14m1!1BCgIgARICCAI!16s%2Fg%2F11l2m45152?entry=ttu\" target=\"_blank\">virtuální prohlídka</a></span><span style=\"color: var(--black);\">.</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
          </ColumnWrap>

        </Column>


        <Column className="css-1355228 --parallax" name={"mapa"} style={{"paddingTop":0,"marginBottom":0,"paddingBottom":683}} layout={"l9"} parallax={true} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/34102/2c6eb660caee4eabac25f71cf1197c9f_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/34102/2c6eb660caee4eabac25f71cf1197c9f_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/34102/2c6eb660caee4eabac25f71cf1197c9f_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/34102/2c6eb660caee4eabac25f71cf1197c9f_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/34102/2c6eb660caee4eabac25f71cf1197c9f_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/34102/2c6eb660caee4eabac25f71cf1197c9f_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/34102/2c6eb660caee4eabac25f71cf1197c9f_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/34102/2c6eb660caee4eabac25f71cf1197c9f_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--bottom" columns={"1"}>
          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"oteviracka"} layout={"l13"}>
          
          <ColumnWrap className="column__flex --left el--2 flex--center" style={{"maxWidth":1537,"paddingRight":0}} columns={"2"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":480}}>
              
              <Title className="title-box" content={"<span style=\"color: var(--black);\">Otevírací doba</span>"}>
              </Title>

              <Title className="title-box fs--36 lh--14 pt--02" content={"<span style=\"color: var(--black);\">Po - Čt&nbsp; &nbsp;09:00 - 20:00<br>Pátek&nbsp; &nbsp; &nbsp;09:00 - 21:00<br>Sobota&nbsp;&nbsp;14:00 - 21:00&nbsp;<br>Neděle&nbsp; 14:00 - 18:00&nbsp;</span><br>"}>
              </Title>

              <Text className="text-box fs--18 w--400 ls--002 lh--18" content={"Z technických důvodů jsme nuceni na zimní období změnit otevírací dobu kavárny a uzavřít kuchyň, která bude k dispozici pouze pro přípravu rautů na objednané oslavy a večírky! Děkujeme za pochopení.<br><br>Otevírací doba se vztahuje k celému areálu kavárny včetně přilehlé zahrady a terasy. Může se lišit ve dnech konání rozsáhlejších akcí. <br>Aktuální program uvádíme tady na webu v záložce AKCE a na našich sociálních sítích&nbsp; ;-)<br>"}>
              </Text>

              <Button className="btn-box btn-box--hvr4 btn-box--pbtn3 btn-box--shape5 btn-box--shadow5 fs--20 w--400 ls--002 lh--18" use={"page"} href={"/akce#j40igf0u8gp"} style={{"marginTop":34}} content={"NA AKCE"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"kavárna Manetka"} src={"https://cdn.swbpg.com/t/34102/24119e2753f644b3a229c753edc30c83_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":1000}} srcSet={"https://cdn.swbpg.com/t/34102/24119e2753f644b3a229c753edc30c83_s=350x_.jpg 350w, https://cdn.swbpg.com/t/34102/24119e2753f644b3a229c753edc30c83_s=660x_.jpg 660w, https://cdn.swbpg.com/t/34102/24119e2753f644b3a229c753edc30c83_s=860x_.jpg 860w, https://cdn.swbpg.com/t/34102/24119e2753f644b3a229c753edc30c83_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/34102/24119e2753f644b3a229c753edc30c83_s=2000x_.jpg 2000w"} lightbox={true} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"cyu3ah8j9f7"} style={{"paddingTop":74,"paddingBottom":81,"backgroundColor":"rgba(96, 96, 96, 1)"}} layout={"l2"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--48" style={{"marginBottom":0}} content={"<span style=\"color: var(--white);\">Fakturační údaje</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper style={{"paddingTop":0}}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/g/Ikonky/Adresa/adresa-6.svg"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":60}} srcSet={""} svgColor={"rgba(255,255,255,1)"} RootClassName={"column__icon"}>
              </Image>

              <Text className="text-box fs--20 w--400 ls--002 lh--18" style={{"marginTop":38}} content={"<span style=\"color: var(--white);\">Caffe Manet s.r.o.\n<br>Sokolovská 2233/76,&nbsp;323 00 Plzeň\n<br>IČ: 19279299\n<br>(Společnost je zapsána v obchodním rejstříku vedeném Krajským soudem v Plzni pod spisovou značkou C 43711.&nbsp;)</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
          </ColumnWrap>

        </Column>


        <Column style={{"marginTop":0,"paddingTop":104,"paddingBottom":103}} name={"paticka"} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--3 flex--top" style={{"maxWidth":1406,"paddingLeft":0,"marginBottom":0}} columns={"3"} fullscreen={false}>
            
            <ColumnWrapper className="--center">
              
              <Title className="title-box fs--20 w--400 ls--002 lh--14" content={"<span style=\"font-weight: bold; color: var(--black);\">WEB</span>"}>
              </Title>

              <Text className="text-box fs--18 w--400 ls--002 lh--16" style={{"marginTop":14,"marginBottom":0,"paddingBottom":30}} content={"<span style=\"color: var(--black);\"><span style=\"text-decoration-line: underline;\"><a href=\"/#bcckfdbfejb\" style=\"\">O nás</a></span><a href=\"/menu#menu_uvod\"><br>Stálé menu<br></a><a href=\"/akce#j40igf0u8gp\">Akce</a><a href=\"/menu#menu_uvod\" style=\"\"><br></a><a href=\"/salonek#salonek_uvod\" style=\"\">Salónek</a><a href=\"/menu#menu_uvod\"><br></a><a href=\"/kontakt#kontakty\" style=\"\">Kontakt</a></span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center">
              
              <Title className="title-box fs--20 w--400 ls--002 lh--14" content={"<span style=\"font-weight: bold; color: var(--black);\">KAVÁRNA A CUKRÁRNA MANETKA</span>"}>
              </Title>

              <Text className="text-box fs--18 w--400 ls--002 lh--16" style={{"maxWidth":396,"marginTop":13,"paddingRight":0,"paddingBottom":0}} content={"<span style=\"color: var(--black);\">Manětínská 30, Plzeň<br>tel: 773 300 833, email: kavarna@manetka.cz</span><br><span style=\"text-decoration-line: underline; font-weight: bold; color: var(--black);\"><a href=\"https://www.google.com/maps/place/Manetka+cafe/@49.7800782,13.3664327,3a,90y,117.55h,89.06t/data=!3m7!1e1!3m5!1sAF1QipPbAylCirgJw9XZmzsz7O3ipPU8vZNmJViK7vnr!2e10!3e12!7i11000!8i5500!4m9!3m8!1s0x470af33bb34100a5:0x17c49104135d9431!8m2!3d49.7798029!4d13.3665938!10e5!14m1!1BCgIgARICCAI!16s%2Fg%2F11l2m45152?entry=ttu\" target=\"_blank\" style=\"\">VIRTUÁLNÍ PROHLÍDKA KAVÁRNY</a></span><br><br><span style=\"font-weight: bold;\">OTEVÍRACÍ DOBA KAVÁRNY</span><br>Po - Čt&nbsp; 09:00 - 20:00, Pá 09:00 - 21:00<br>So 14:00 - 21:00, Ne&nbsp;14:00 - 18:00<br>"}>
              </Text>

              <Image style={{"maxWidth":124,"marginTop":33}} alt={""} src={"https://cdn.swbpg.com/t/34102/2706bcfce46e4dc5857125e2696c040e_s=860x_.png"} use={"page"} href={"/#bcckfdbfejb"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/34102/2706bcfce46e4dc5857125e2696c040e_s=350x_.png 350w, https://cdn.swbpg.com/t/34102/2706bcfce46e4dc5857125e2696c040e_s=660x_.png 660w, https://cdn.swbpg.com/t/34102/2706bcfce46e4dc5857125e2696c040e_s=860x_.png 860w, https://cdn.swbpg.com/t/34102/2706bcfce46e4dc5857125e2696c040e_s=1400x_.png 1400w, https://cdn.swbpg.com/t/34102/2706bcfce46e4dc5857125e2696c040e_s=2000x_.png 2000w"} lightbox={false}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--center">
              
              <Title className="title-box fs--20 w--400 ls--002 lh--14" content={"<span style=\"font-weight: bold; color: var(--black);\">SLEDUJ NÁS</span>"}>
              </Title>

              <Image style={{"maxWidth":64,"marginTop":31}} alt={"Facebook"} src={"https://cdn.swbpg.com/t/34102/556bd886db6346cc9a4b6ae94cde69b1_s=350x_.png"} url={"https://www.facebook.com/Manetkacafe"} use={"url"} href={"https://www.facebook.com/Manetkacafe"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/34102/556bd886db6346cc9a4b6ae94cde69b1_s=350x_.png 350w"} lightbox={false}>
              </Image>

              <Image style={{"maxWidth":64,"marginTop":35}} alt={"Instagram"} src={"https://cdn.swbpg.com/t/34102/9fa843421abe4a449de7c1ad3493add4_s=350x_.png"} url={"https://www.instagram.com/manetka_cafe/"} use={"url"} href={"https://www.instagram.com/manetka_cafe/"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/34102/9fa843421abe4a449de7c1ad3493add4_s=350x_.png 350w"} lightbox={false}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}